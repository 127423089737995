/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';
import { NavigateFunction } from 'react-router-dom';
import { store } from '../../../Store/Store';
import { setNavigateTo } from '../../../Store/Slices/UI/sliceUI';

export const ActionSetQuery = (arg?: TSiteActionProps['argument']) => {
  if (!arg || !_.isObject(arg)) {
    console.log('Argument must be { [param:string]: string } type');
    return;
  }

  const currentSearch: { [a: string]: string } | undefined = !window.location
    .search
    ? undefined
    : window.location.search
        .slice(1)
        .split('&')
        .reduce(
          (r, v) => ({
            ...r,
            [v.split('=')[0]]: v.split('=')[1].split(';')[0],
          }),
          {}
        );

  const newSearch: Record<string, string> = _.merge(currentSearch, arg);

  const newSearchString =
    '?' +
    Object.keys(newSearch)
      .map((key) => `${key}=${newSearch[key]}`)
      .join('&');

  const url = window.location.pathname + newSearchString;
  store.dispatch(setNavigateTo({ url }));
};
