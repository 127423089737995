import { takeEvery } from 'redux-saga/effects';
import { getCollectionRequest } from '../Actions/actionsExtra';
import { workerGetCollection } from '../Workers/workerGetCollection';

/**
 * Сага запроса данных с сервера
 */
export function* sagaGetCollection() {
  yield takeEvery(getCollectionRequest, workerGetCollection);
}
