/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { TCustomHTML } from './types';
import _ from 'lodash';
import { fetchTemplateFile } from './helpers/fetchTemplateFile';

export const CustomHTML: React.FC<TCustomHTML> = (props) => {
  const [content, setContent] = useState<string>();

  if (!props.url) {
    console.log('url attribute required!');
    return null;
  }

  fetchTemplateFile(props.url, setContent);

  console.log(props.url);

  const divProps = {
    ..._.omit(props, ['url', 'children']),
    dangerouslySetInnerHTML: { __html: content || '' },
  };
  return <div {...divProps} />;
};
