import _ from 'lodash';
import { TSliceStructure } from './sliceStructure';
import { schemaNormalize } from '../../Normalizers/schemaNormalize';
import { denormalize } from 'normalizr';
import { TSiteSettings } from '../../../Types/typesStructure';

export const getSiteSettings = (
  allSets: TSliceStructure['items']['siteSets'],
  alerts: TSliceStructure['items']['alerts'],
  locale: string
): TSiteSettings | undefined => {
  const sets = _.find(allSets, { locale });
  if (!sets) return;
  const normSchema = schemaNormalize('siteSets', true);
  if (!normSchema) return;
  return denormalize(sets, normSchema(), { alerts });
};
