import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import sliceUI from './Slices/UI/sliceUI';
import sliceStructure from './Slices/Structure/sliceStructure';
import sliceContent from './Slices/Content/sliceContent';
import { sagaRoot } from './Sagas/sagaRoot';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    sliceUI,
    sliceStructure,
    sliceContent,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload.action'],
      },
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(sagaRoot);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
