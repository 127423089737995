import _ from 'lodash';
import { Mutator } from '../../../../../Library/Mutator/Mutator';
import { TFormInputContext } from '../types';
import { TCustomInputSimpleContext } from '../../CustomInputSimple/types';

export const validateInput = (
  input: TFormInputContext,
  mutator: Mutator,
  requiredErrorFeedback?: string
): TFormInputContext => {
  const validationSchema = _.get(input, 'validationSchema');
  if (!input.required && !validationSchema) return input;

  const inputContext = {
    name: input.name,
    value: input.value,
    required: input.required,
    touched: input.touched,
  };

  mutator.addContext({
    current: { input: inputContext as unknown as TCustomInputSimpleContext },
  });
  const validate = () => {
    if (!validationSchema || !input.value) return [];
    return _.reduce(
      validationSchema,
      (r, v) => {
        const isValid = mutator.applyStringVariables(v.isValid);
        if (!isValid) return [...r, v.feedback];
        return [...r];
      },
      [] as string[]
    );
  };

  const errors = input.required
    ? !input.value
      ? [requiredErrorFeedback || 'Обязательное поле']
      : validate()
    : validate();

  return {
    ...input,
    errors,
  };
};
