import _ from 'lodash';
import { TTemplateElem } from './types';
import { templateElements } from './Helpers/templateElements';
import { mutantTemplateElement } from './Helpers/mutantTemplateElement';
import React from 'react';
import { execMultiplyActions } from '../../../../Library/Action/Helpers/execMultiplyActions';
import { DefaultElement } from './Components/DefaultElement';
import { Mutator } from '../../../../Library/Mutator/Mutator';
import { CustomElement } from './Components/CustomElement';
import { useMutationContexts } from '../../../../Library/Mutator/Hooks/useMutationContexts';

export const TemplateElement: React.FC<TTemplateElem> = (templateElement) => {
  const mutationContexts = useMutationContexts(templateElement.contexts);
  // console.log(templateElement);
  const mutator = new Mutator(mutationContexts);
  const mutantElement = mutantTemplateElement(
    mutator,
    _.omit(templateElement, 'contexts') as TTemplateElem,
    templateElement.contexts?.template?.path
  );

  // Запускаю экшны при загрузке компонента
  React.useEffect(() => {
    if (mutantElement?.onLoad) {
      execMultiplyActions(
        _.isArray(mutantElement.onLoad)
          ? mutantElement.onLoad
          : [mutantElement.onLoad],
        mutator
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutantElement?.onLoad]);

  if (!mutantElement || mutantElement.excluded) return null;

  const customElement = _.get(templateElements, mutantElement.tag || 'unknown');

  const domElement = customElement ? (
    <CustomElement
      {...{
        component: customElement,
        templateElement: {
          ...mutantElement,
          contexts: templateElement.contexts,
        },
      }}
    />
  ) : (
    <DefaultElement
      {...{ ...mutantElement, contexts: templateElement.contexts }}
    />
  );

  return <>{domElement}</>;
};
