import { takeEvery } from 'redux-saga/effects';
import { collectionItemSubmit } from '../Actions/actionsExtra';
import { workerCollectionItemSubmit } from '../Workers/workerCollectionItemSubmit';

/**
 * Сага отправки контента на сервер
 */
export function* sagaCollectionItemSubmit() {
  yield takeEvery(collectionItemSubmit, workerCollectionItemSubmit);
}
