/**
 * Actions constants
 */
export const APP_INITIAL_STATE = 'app/initial';
export const GET_INITIAL_REQUEST = 'get-initial/request';
export const GET_INITIAL_REQUEST_PENDING = 'get-initial/request-pending';
export const GET_INITIAL_REQUEST_REJECTED = 'get-initial/request-rejected';
export const GET_INITIAL_REQUEST_SUCCEED = 'get-initial/request-succeed';

export const GET_ME_REQUEST = 'get-me/request';
export const GET_ME_REQUEST_PENDING = 'get-me/request-pending';
export const GET_ME_REQUEST_SUCCEED = 'get-me/request-succeed';
export const GET_ME_REQUEST_REJECTED = 'get-me/request-rejected';

export const GET_LS_DATA_REQUEST = 'get-ls-data/request';
export const GET_LS_DATA_REQUEST_SUCCEED = 'get-ls-data/request-succeed';

export const GET_COLLECTION_REQUEST = 'get-collection/request';
export const GET_COLLECTION_REQUEST_PENDING = 'get-collection/request-pending';
export const GET_COLLECTION_REQUEST_SUCCEED = 'get-collection/request-succeed';
export const GET_COLLECTION_REQUEST_REJECTED =
  'get-collection/request-rejected';

export const COLLECTION_ITEM_SUBMIT = 'collection-item/submit';
export const COLLECTION_ITEM_SUBMIT_PENDING = 'collection-item/submit-pending';
export const COLLECTION_ITEM_SUBMIT_SUCCEED = 'collection-item/submit-succeed';
export const COLLECTION_ITEM_SUBMIT_REJECTED =
  'collection-item/submit-rejected';

export const AUTH_SUBMIT = 'auth/submit';
export const AUTH_SUBMIT_PENDING = 'auth/submit-pending';
export const AUTH_SUBMIT_SUCCEED = 'auth/submit-succeed';
export const AUTH_SUBMIT_REJECTED = 'auth/submit-rejected';
