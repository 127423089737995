import { schema } from 'normalizr';
import schemaNormalizeItemTemplates from './schemaNormalizeItemTemplates';

/**
 * Нормализация страниц сайта
 *
 * @param depth - глубина нормализации
 * @returns схема
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaNormalizeSitePages = (): schema.Entity => {
  return new schema.Entity('sitePages', {
    template: schemaNormalizeItemTemplates(),
  });
};

export default schemaNormalizeSitePages;
