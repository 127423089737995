import QueryString from 'qs';
import { TRequestMethod, TResponseObject } from '../../../Types/typesFetch';
import { TBaseDataObject } from '../../../Types/typesGlobal';
import { store } from '../../Store';

export type TRequestProps = {
  url: string;
  method?: TRequestMethod;
  headers?: { [a: string]: string };
  parameters?: Partial<TBaseDataObject>;
  body?: TBaseDataObject;
};

/**
 * Асинхронно запрашивает данные из API с добавлением токена авторизации и параметров запроса.
 *
 * fetchApa - асинхронная функция, которая запрашивает данные из API, добавляя к запросу токен авторизации и параметры запроса.
 * Функция принимает объект request в качестве параметра и возвращает promise, с полученными данными или отклоняет с объектом error.
 * Сначала она проверяет наличие токена авторизации и добавляет его в заголовки запроса, если он существует.
 * Затем формирует строку запроса и добавляет ее в URL-адрес запроса.
 * Наконец, функция использует Fetch API для выполнения фактического HTTP-запроса, обрабатывая ответ на основе его типа содержимого.
 *
 * @param {TRequestProps} request - the request object containing URL, method, headers, parameters, and body
 * @return {Promise<any>} a Promise that resolves to the fetched data or rejects with an error object
 */

export const fetchApi = async (
  request: TRequestProps
): Promise<TResponseObject> => {
  //Добавляю токен авторизации (если есть)
  const jwt = store.getState().sliceUI.jwt;
  if (jwt)
    request.headers = { ...request.headers, Authorization: `Bearer ${jwt}` };

  // Формирую строку параметров запроса с добавлением локали
  const queryString = QueryString.stringify(request.parameters);

  // Добавляю параметры запроса
  const url = `${request.url}${queryString ? '?' + queryString : ''}`;

  // console.log(url, { method: request.method, headers: request.headers, body: JSON.stringify(request.body) })

  const fetchObj = fetch(url, {
    method: request.method,
    headers: request.headers,
    body: JSON.stringify(request.body),
  }).then((res) => {
    try {
      return res.json();
    } catch {
      return {
        error: {
          status: 500,
          name: 'server error',
          message: 'Incorrect server response format',
          details: {},
        },
      };
    }
  });
  return fetchObj;
};
