import { schema } from 'normalizr';

/**
 * Нормализация юнитов
 *
 * @param depth - глубина нормализации
 * @returns схема
 */
const schemaNormalizeUnits = (): schema.Entity => {
  return new schema.Entity('units', {
    ownerUnit: new schema.Entity('units'),
    ownUnits: [new schema.Entity('units')],
    memberUnits: [new schema.Entity('units')],
    membershipUnits: [new schema.Entity('units')],
    confirmedMembershipUnits: [new schema.Entity('units')],
    ownerPerson: new schema.Entity('users'),
    memberPersons: [new schema.Entity('users')],
    type: new schema.Entity('unitTypes'),
  });
};

export default schemaNormalizeUnits;
