import { createSlice } from '@reduxjs/toolkit';
import { TIcon } from '../../../Components/Page/Components/CustomIcon/types';
import {
  TAlert,
  TSitePage,
  TSiteSettings,
  TTemplate,
} from '../../../Types/typesStructure';
import {
  setInitialState,
  getInitialRequestPending,
  getInitialRequestRejected,
  getInitialRequestSucceed,
} from '../../Actions/actionsExtra';
import { TCollectionNorm } from '../types';
import _ from 'lodash';
import { TItemType } from '../../../Types/typesUnit';

export type TSliceStructure = {
  collections: Record<string, TCollectionNorm>;
  items: {
    siteSets: Record<string, TSiteSettings>;
    sitePages: Record<string, TSitePage>;
    itemTemplates: Record<string, TTemplate>;
    icons: Record<string, TIcon>;
    alerts: Record<string, TAlert>;
    itemTypes: Record<string, TItemType>;
  };
};

const initialState: TSliceStructure = {
  collections: {},
  items: {
    siteSets: {},
    sitePages: {},
    itemTemplates: {},
    icons: {},
    alerts: {},
    itemTypes: {},
  },
};

const sliceStructure = createSlice({
  name: 'structure',
  initialState,

  extraReducers: (builder) => {
    builder.addCase(setInitialState, () => initialState);

    builder.addCase(getInitialRequestPending, (state) => {
      _.set(state.collections, 'initial', {
        id: 'initial',
        fetchStatus: 'progress',
      });
    });

    builder.addCase(getInitialRequestRejected, (state, { payload }) => {
      _.set(state.collections, 'initial.error', payload);
    });

    builder.addCase(getInitialRequestSucceed, (state, { payload }) => {
      _.set(state.collections, 'initial.fetchStatus', 'success');
      state.items = _.merge(state.items, payload);
    });
  },
  reducers: {
    cleanupStructure: (state: TSliceStructure) => {
      Object.keys(initialState).forEach((key) => {
        const initialValue = _.get(initialState, key);
        _.set(state, key, initialValue);
      });
    },
  },
});

export const { cleanupStructure } = sliceStructure.actions;

export default sliceStructure.reducer;
