import { TCustomInputImageContext } from '../types';

export const constructSrc = (
  imageDataUrl: string,
  file: File,
  setState: React.Dispatch<
    React.SetStateAction<TCustomInputImageContext['src']>
  >
) => {
  const image = new Image();
  image.src = imageDataUrl;

  image.onload = () => {
    setState({
      file,
      dataURL: imageDataUrl,
      type: file.type,
      sizeX: image.width,
      sizeY: image.height,
    });
    return;
  };

  image.onerror = () => {
    setState({
      file,
      dataURL: imageDataUrl,
      type: file.type,
      sizeX: 0,
      sizeY: 0,
    });
    return;
  };
};
