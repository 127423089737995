/* eslint-disable @typescript-eslint/no-unused-vars */
import { denormalize } from 'normalizr';
import { schemaNormalize } from '../../Normalizers/schemaNormalize';
import { RootState } from '../../Store';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { TUserNorm } from '../../../Types/typesUser';
import { TSliceContent } from './sliceContent';

export const getMyUser = (user: TUserNorm, items: TSliceContent['items']) => {
  const normSchema = schemaNormalize('users', true);
  if (!normSchema) return;
  return denormalize(user, normSchema(), items);
};
