/* eslint-disable import/no-extraneous-dependencies */
import Cropper from 'react-easy-crop';
import { TCropperProps } from './types';
import { Point } from 'react-easy-crop/types';
import { useState } from 'react';

export const CustomCropper: React.FC<TCropperProps> = ({ ...props }) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });

  return <Cropper crop={crop} onCropChange={setCrop} {...props} />;
};
