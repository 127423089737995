import _ from 'lodash';
import { useMemo } from 'react';
import { store } from '../../../../../Store/Store';

/**
 * Возвращает макет по id или имени
 *
 * @param id - id макет
 * @param name - имя макет
 */
export const useTemplate = (id?: string, name?: string) => {
  const templates = store.getState().sliceStructure.items.itemTemplates;
  const template = useMemo(() => {
    if (id) return templates[id];
    if (name) return _.find(templates, ['name', name]);
    return null;
  }, [id, name, templates]);
  if (!template) {
    if (!id && !name) {
      console.log('Template must have id or name attribute!');
    }
    console.log('Template not found', { id, name });
    return;
  }
  return template;
};
