import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { getInitialRequest } from './Store/Actions/actionsExtra';
import { RootState } from './Store/Store';
import { DEFAULT_PAGES } from './Settings/template';
import { getSitePages } from './Store/Slices/Structure/getSitePages';
import { Page } from './Components/Page/Page';
import { selectLocale } from './Store/Slices/UI/selectors';
import {
  selectItemTemplates,
  selectSitePages,
} from './Store/Slices/Structure/selectors';

/**
 *  Компонент приложения
 *
 * @returns приложение
 */
const App: React.FC = () => {
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();

  const appLoadingStatus = useSelector(
    (state: RootState) =>
      state.sliceStructure.collections.initial?.fetchStatus || 'initial'
  );

  useEffect(() => {
    if (appLoadingStatus === 'initial') {
      dispatch(getInitialRequest());
    }
  }, [appLoadingStatus, dispatch]);

  const normPages = useSelector(selectSitePages);
  const templates = useSelector(selectItemTemplates);
  const storePages = useMemo(
    () => getSitePages(normPages, templates, locale),
    [locale, normPages, templates]
  );

  const pages =
    appLoadingStatus === 'success'
      ? storePages.find((page) => page.url === '/404')
        ? storePages
        : [...storePages, DEFAULT_PAGES.error404]
      : appLoadingStatus === 'error'
      ? [{ ...DEFAULT_PAGES.error500, url: '*' }]
      : Object.values(DEFAULT_PAGES);

  const routes = pages.map((page) => ({
    id: page.id,
    path: page.url,
    element: <Page {...page} />,
  }));

  return <RouterProvider router={createBrowserRouter(routes)} />;
};

export default App;
