import _ from 'lodash';
import { TFormInputContext } from '../types';
import { Mutator } from '../../../../../Library/Mutator/Mutator';
import { validateInput } from './validateInput';

export const validateForm = (
  mutator: Mutator,
  inputs: Record<string, TFormInputContext>
): Record<string, TFormInputContext> => {
  return _.reduce(
    inputs,
    (r, v, k) => ({
      ...r,
      [k]: validateInput(v, mutator),
    }),
    {}
  );
};
