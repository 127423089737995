import { createAction } from '@reduxjs/toolkit';
import {
  APP_INITIAL_STATE,
  GET_INITIAL_REQUEST,
  GET_LS_DATA_REQUEST,
  GET_LS_DATA_REQUEST_SUCCEED,
  GET_INITIAL_REQUEST_PENDING,
  GET_INITIAL_REQUEST_REJECTED,
  GET_INITIAL_REQUEST_SUCCEED,
  GET_COLLECTION_REQUEST,
  GET_COLLECTION_REQUEST_PENDING,
  GET_COLLECTION_REQUEST_REJECTED,
  GET_COLLECTION_REQUEST_SUCCEED,
  COLLECTION_ITEM_SUBMIT,
  COLLECTION_ITEM_SUBMIT_PENDING,
  COLLECTION_ITEM_SUBMIT_REJECTED,
  COLLECTION_ITEM_SUBMIT_SUCCEED,
  AUTH_SUBMIT,
  AUTH_SUBMIT_PENDING,
  AUTH_SUBMIT_REJECTED,
  AUTH_SUBMIT_SUCCEED,
} from '../../Settings/extraActions';
import { TBaseDataObject } from '../../Types/typesGlobal';
import { TSliceUI } from '../Slices/UI/sliceUI';
import {
  TCollectionRequest,
  TCollectionItemSubmit,
  TCollectionItemSubmitSuccess,
  TResponseObject,
  TAuthDataSubmit,
  TAuthDataSubmitSuccess,
  TAuthDataSubmitPending,
  TAuthDataSubmitError,
  TCollectionItemSubmitPending,
  TCollectionItemSubmitError,
  TCollectionRequestPending,
  TCollectionRequestRejected,
  TCollectionRequestSucceed,
} from '../../Types/typesFetch';
import { TSliceStructure } from '../Slices/Structure/sliceStructure';

export const setInitialState = createAction(APP_INITIAL_STATE);
export const getInitialRequest = createAction(GET_INITIAL_REQUEST);
export const getInitialRequestPending = createAction(
  GET_INITIAL_REQUEST_PENDING
);
export const getInitialRequestRejected = createAction<TResponseObject['error']>(
  GET_INITIAL_REQUEST_REJECTED
);
export const getInitialRequestSucceed = createAction<
  Partial<TSliceStructure['items']>
>(GET_INITIAL_REQUEST_SUCCEED);

export const getLsDataRequest =
  createAction<TBaseDataObject>(GET_LS_DATA_REQUEST);
export const getLsDataRequestSucceed = createAction<
  Partial<TSliceUI> | undefined
>(GET_LS_DATA_REQUEST_SUCCEED);

export const getCollectionRequest = createAction<TCollectionRequest>(
  GET_COLLECTION_REQUEST
);
export const getCollectionRequestPending =
  createAction<TCollectionRequestPending>(GET_COLLECTION_REQUEST_PENDING);
export const getCollectionRequestRejected =
  createAction<TCollectionRequestRejected>(GET_COLLECTION_REQUEST_REJECTED);
export const getCollectionRequestSucceed =
  createAction<TCollectionRequestSucceed>(GET_COLLECTION_REQUEST_SUCCEED);

export const collectionItemSubmit = createAction<TCollectionItemSubmit>(
  COLLECTION_ITEM_SUBMIT
);
export const collectionItemSubmitPending =
  createAction<TCollectionItemSubmitPending>(COLLECTION_ITEM_SUBMIT_PENDING);
export const collectionItemSubmitRejected =
  createAction<TCollectionItemSubmitError>(COLLECTION_ITEM_SUBMIT_REJECTED);
export const collectionItemSubmitSucceed =
  createAction<TCollectionItemSubmitSuccess>(COLLECTION_ITEM_SUBMIT_SUCCEED);

export const authDataSubmit = createAction<TAuthDataSubmit>(AUTH_SUBMIT);
export const authDataSubmitPending =
  createAction<TAuthDataSubmitPending>(AUTH_SUBMIT_PENDING);
export const authDataSubmitRejected =
  createAction<TAuthDataSubmitError>(AUTH_SUBMIT_REJECTED);
export const authDataSubmitSucceed =
  createAction<TAuthDataSubmitSuccess>(AUTH_SUBMIT_SUCCEED);
