import _ from 'lodash';
import { TTemplateContext } from '../../TemplateElement/types';
import { TBaseDataObject } from '../../../../../Types/typesGlobal';

const createAllDepth = (variables: TBaseDataObject): Record<string, 'all'> => {
  return _.reduce(variables, (r, v, k) => ({ ...r, [k]: 'all' }), {});
};

const updateVarDepth = (
  varDepth: Record<string, number | 'all'>
): Record<string, number | 'all'> => {
  return _.reduce(
    varDepth,
    (r, v, k) => {
      if (v === 'all') return { ...r, [k]: v };
      if (v - 1 === 0) return { ...r };
      return { ...r, [k]: v - 1 };
    },
    {}
  );
};

const updateVars = (
  vars: TBaseDataObject,
  varDepth: Record<string, number | 'all'>
) => {
  return _.reduce(
    vars,
    (r, v, k) => {
      if (!varDepth[k]) return { ...r };
      return { ...r, [k]: v };
    },
    {}
  );
};

type TUpdate = Pick<TTemplateContext, 'variables' | 'variablesDepth'>;
const updateOld = (obj: TUpdate): TUpdate => {
  const { variables, variablesDepth } = obj;
  if (!variables || !variablesDepth)
    return { variables: {}, variablesDepth: {} };

  if (variablesDepth === 'all')
    return {
      variables,
      variablesDepth: createAllDepth(variables),
    };

  const newDepth = updateVarDepth(variablesDepth);

  return {
    variables: updateVars(variables, newDepth),
    variablesDepth: newDepth,
  };
};

export const updateElemVariables = (
  newObj: TUpdate,
  oldObj: TUpdate | undefined,
  addOnly?: boolean
): TUpdate => {
  if (!oldObj) return newObj;

  const updatedOldData = updateOld(oldObj);
  return {
    variables: _.merge(
      addOnly ? oldObj.variables : updatedOldData.variables,
      newObj.variables
    ),
    variablesDepth: _.merge(
      addOnly
        ? oldObj.variablesDepth === 'all'
          ? createAllDepth(oldObj.variables || {})
          : oldObj.variablesDepth
        : updatedOldData.variablesDepth,
      newObj.variablesDepth === 'all'
        ? newObj.variables
          ? createAllDepth(newObj.variables)
          : {}
        : newObj.variablesDepth
    ),
  };
};
