import { Icon } from '@iconify/react';
import _ from 'lodash';
import { store } from '../../../../Store/Store';
import { getIcon } from './Helpers/getIcon';
import { defaultIcon } from './Helpers/defaultIcon';
import { TCustomIcon } from './types';
import { useMemo } from 'react';

/**
 * Компонент иконки
 *
 * @param props - Свойства компонента
 * @returns иконка
 */
export const CustomIcon: React.FC<TCustomIcon> = (props) => {
  const rootState = store.getState();
  const icon =
    useMemo(
      () => _.find(rootState.sliceStructure.items.icons, { name: props.icon }),
      [props.icon, rootState.sliceStructure.items.icons]
    ) || defaultIcon;

  const iconProps = {
    ..._.omit(props, ['icon', 'active']),
    icon: getIcon(icon, props.active),
  };
  return <Icon {...iconProps} />;
};
