import { schema } from 'normalizr';
import schemaNormalizeAlerts from './schemaNormalizeAlerts';

/**
 * Нормализация юзеров
 *
 * @param depth - глубина нормализации
 * @returns схема
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaNormalizeUsers = (): schema.Entity => {
  return new schema.Entity('users', {
    ownUnits: [new schema.Entity('units')],
    membershipUnits: [new schema.Entity('units')],
    confirmedMembershipUnits: [new schema.Entity('units')],
    alerts: [schemaNormalizeAlerts()],
  });
};

export default schemaNormalizeUsers;
