import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  TBaseDataItem,
  TBaseDataObject,
  TFetchStatus,
  TSiteActionProps,
  TToast,
} from '../../../Types/typesGlobal';
import {
  authDataSubmitPending,
  authDataSubmitRejected,
  authDataSubmitSucceed,
  getLsDataRequestSucceed,
  setInitialState,
} from '../../Actions/actionsExtra';
import _ from 'lodash';
import { LOCALE_DEFAULT } from '../../../Settings/api';
import { TSiteSettings } from '../../../Types/typesStructure';
import { updateLocalStorage } from './updateLocalStorage';
import { TAuthDataSubmit, TResponseObject } from '../../../Types/typesFetch';

type TAuthStatus = {
  type: TAuthDataSubmit['type'];
  status: TFetchStatus;
  error?: TResponseObject['error'];
};

/**
 * Определяет объект хранилища состояний пользовательского интерфейса.
 */
export type TSliceUI = {
  auth: Record<string, TAuthStatus>;
  locale: string;
  meID?: string;
  jwt?: string;
  customStates: Record<
    string,
    TBaseDataItem | TBaseDataItem[] | TBaseDataObject | TBaseDataObject[]
  >;
  attributesSave: TSiteSettings['UIAttributesSave'];
  toasts: Record<string, TToast & { show?: boolean }>;
  navigateTo?: {
    url: string;
    action?: TSiteActionProps | TSiteActionProps[];
  };
  navigateBack?: boolean;
};

const initialState: TSliceUI = {
  auth: {},
  meID: undefined,
  jwt: undefined,
  locale: LOCALE_DEFAULT,
  customStates: {},
  attributesSave: {},
  toasts: {},
};

/**
 * Слайс состояний пользовательского интерфейса
 */
const sliceUI = createSlice({
  name: 'UI',
  initialState,

  /**
   * Методы для изменения состояний
   * @param builder - слайс данных
   */
  extraReducers: (builder) => {
    builder.addCase(setInitialState, () => initialState);

    builder.addCase(getLsDataRequestSucceed, (state, { payload }) => {
      if (payload) {
        const payloadKeys = Object.keys(_.omit(payload, ['me']));
        payloadKeys.forEach((key) => {
          const value = _.get(payload, key);
          _.set(state, key, value);
        });
      }
    });

    builder.addCase(authDataSubmitPending, (state, { payload }) => {
      state.auth[payload.type] = {
        type: payload.type,
        status: 'progress',
      };
    });

    builder.addCase(authDataSubmitRejected, (state, { payload }) => {
      state.auth[payload.type] = {
        type: payload.type,
        status: 'error',
        error: payload.error,
      };
    });

    builder.addCase(authDataSubmitSucceed, (state, { payload }) => {
      state.auth[payload.type] = {
        type: payload.type,
        status: 'success',
        error: undefined,
      };

      state.jwt = payload.jwt;
      state.meID = Object.keys(payload.items?.users || {})[0];
      updateLocalStorage(state);
    });
  },
  reducers: {
    /**
     * Устанавливает значение локали
     * @param state - текущее состояние слайса
     * @param payload - значение локали
     */
    setLocale: (state: TSliceUI, { payload }: PayloadAction<string>) => {
      state.locale = payload;
      updateLocalStorage(state);
    },

    /**
     * Устанавливает ID пользователя
     * @param state - текущее состояние слайса
     * @param payload - ID пользователя
     */
    setMeID: (state: TSliceUI, { payload }: PayloadAction<string>) => {
      state.meID = payload;
    },

    setJWT: (state: TSliceUI, { payload }: PayloadAction<string>) => {
      state.jwt = payload;
    },

    /**
     * Очистка JWT токена из локального хранилища
     * @param state - текущее состояние слайса
     */
    cleanupJWT: (state: TSliceUI) => {
      state.jwt = undefined;
      updateLocalStorage({ ...state, jwt: undefined });
    },

    /**
     * Устанавливает кастомное состояние пользовательского интерфейса
     * @param state - текущее состояние слайса
     * @param payload - имя кастомного состояния и значение
     */
    setCustomState: (
      state,
      {
        payload,
      }: PayloadAction<{
        name: string;
        value?: TSliceUI['customStates'][''];
      }>
    ) => {
      _.set(state.customStates, payload.name, payload.value);
      updateLocalStorage(state);
    },

    /**
     * Добавляет объект всплывающего уведомления
     * @param state - текущее состояние слайса
     * @param payload - объект уведомления
     */
    setToast: (state: TSliceUI, { payload }: PayloadAction<TToast>) => {
      _.set(state.toasts, payload.id, { ...payload, show: false });
    },

    /**
     * Скрывает/показывает всплывающее уведомление
     * @param state - текущее состояние слайса
     * @param payload - id всплывающего уведомления
     */
    switchShowToast: (state: TSliceUI, { payload }: PayloadAction<string>) => {
      const toast = state.toasts[payload];
      if (toast) {
        if (toast.show === undefined) {
          toast.show = true;
        } else {
          toast.show = !toast.show;
        }
      }
    },

    /**
     * Удаляет всплывающее уведомление
     * @param state - текущее состояние слайса
     * @param payload - id всплывающего уведомления
     */
    delToast: (state: TSliceUI, { payload }: PayloadAction<string>) => {
      if (state.toasts[payload]) delete state.toasts[payload];
    },

    setNavigateTo: (
      state: TSliceUI,
      {
        payload,
      }: PayloadAction<
        | string
        | { url: string; action?: TSiteActionProps | TSiteActionProps[] }
        | undefined
      >
    ) => {
      if (_.isString(payload)) {
        state.navigateTo = {
          url: payload,
        };
      } else {
        state.navigateTo = payload;
      }
    },

    setNavigateBack: (state: TSliceUI) => {
      state.navigateBack = !state.navigateBack;
    },

    cleanupUI: (state: TSliceUI) => {
      Object.keys(initialState).forEach((key) => {
        const initialValue = _.get(initialState, key);
        _.set(state, key, initialValue);
      });
    },
  },
});

export const {
  setLocale,
  setMeID,
  setJWT,
  cleanupJWT,
  setCustomState,
  setToast,
  switchShowToast,
  delToast,
  setNavigateTo,
  setNavigateBack,
  cleanupUI,
} = sliceUI.actions;
export default sliceUI.reducer;
