import _ from 'lodash';
import { TemplateElement } from '../TemplateElement';
import { TTemplateElem } from '../types';
import { TMutationContext } from '../../../../../Library/Mutator/types';

/**
 * Компонент для отображения массива корневых элементов макета (верхнего уровня)
 *
 * @param elements - массив элементов макета
 * @param schema - схема изменения массива
 *
 * @returns React-компоненты элементов макета
 */
export const TemplateElements: React.FC<{
  elements: TTemplateElem['children'];
  schema?: TTemplateElem['mutationSchema'];
  contexts: TMutationContext['current'];
}> = ({ elements, schema, contexts }) => {
  if (!_.isObject(elements)) return null;
  return (
    <>
      {_.isArray(elements) ? (
        elements.map((templateItem, i) => {
          if (!_.isObject(templateItem)) return null;
          return (
            <TemplateElement
              key={i}
              {...{
                ...templateItem,
                mutationSchema: {
                  ...(schema || templateItem.mutationSchema || {}),
                  path: [...(schema?.path || []), `[${i}]`],
                },
                contexts,
              }}
            />
          );
        })
      ) : (
        <TemplateElement
          {...{
            ...elements,
            mutationSchema: {
              ...(schema || elements.mutationSchema || {}),
            },
            contexts,
          }}
        />
      )}
    </>
  );
};
