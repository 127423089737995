import { all } from 'redux-saga/effects';
import { sagaInitialWatch } from './sagaInitial';
import { sagaGetCollection } from './sagaGetCollection';
import { sagaCollectionItemSubmit } from './sagaCollectionItemSubmit';
import { sagaAuthDataSubmit } from './sagaAuthDataSubmit';

/**
 * Корневая сага
 */
export function* sagaRoot() {
  yield all([
    sagaInitialWatch(),
    sagaGetCollection(),
    sagaCollectionItemSubmit(),
    sagaAuthDataSubmit(),
  ]);
}
