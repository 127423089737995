import _ from 'lodash';
import { TAlert } from '../../../Types/typesStructure';
import { TSliceStructure } from './sliceStructure';

/**
 * Получить объект уведомлений для страницы по ID страницы
 *
 * @param state Объект хранилища
 * @param pageID ID страницы
 * @returns объект уведомлений
 */
export const getPageAlerts = (
  pageID: string,
  alerts: TSliceStructure['items']['alerts'],
  locale: string
): TAlert[] => {
  return _.filter(
    alerts,
    (v) =>
      v.locale === locale &&
      !!v.showPositions.find((position) => position.page === pageID)
  );
};
