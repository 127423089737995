import _ from 'lodash';
import { TBaseDataObject, TAbstractStoreObject } from '../../Types/typesGlobal';
import { TMutationContext } from './types';
import { TAG_MUTATION } from '../../Components/Page/Components/TemplateElement/constants';
import { MUTATION_OBJECT } from './constants';
import { constructMutatorContexts } from './Helpers/constructMutatorContexts';

/**
 * Класс для мутации объектов данных.
 *
 * Осуществляет замену строковых значений в атрибутах объекта на выражения,
 * которые будут интерпретированы в контексте выполнения {@linkcode Mutator.mutate|метода mutate}
 * с учетом текущего контекста {@linkcode Mutator.context|контекста}.
 *
 * Класс используется для {@linkcode Mutator.mutate|мутации} объектов данных
 * в процессе их загрузки в приложение.
 *
 * {@linkcode Mutator|Ссылка на класс}
 */

export class Mutator {
  context: TMutationContext;

  attrInclude: RegExp[] | undefined = TAG_MUTATION.INCLUDE.ATTRIBUTES.all;

  attrExclude: RegExp[] | undefined = TAG_MUTATION.EXCLUDE.ATTRIBUTES.all;

  valInclude: RegExp[] | undefined = TAG_MUTATION.INCLUDE.VALUES.all;

  valExclude: RegExp[] | undefined = TAG_MUTATION.EXCLUDE.VALUES.all;

  private srcObject: TAbstractStoreObject | undefined;

  /** Констуктор мутатора (устанавливается контекст) */
  constructor(context?: Partial<TMutationContext>) {
    const storeContext = constructMutatorContexts();
    this.context = _.merge(storeContext, context || {});
  }

  /**
   * Добавить контекст мутации
   * @param context Контекст
   * @returns Новый контекст
   */
  addContext(context: Partial<TMutationContext>) {
    this.context = _.merge(this.context, context);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setContextValue(path: string, value: any) {
    _.set(this.context, path, value);
  }

  /**
   * Включить указанные имена атрибутов для мутации (по умолчанию - все)
   */
  setAttrInclude(values?: RegExp[] | RegExp) {
    if (!values) {
      this.attrInclude = TAG_MUTATION.INCLUDE.ATTRIBUTES.all;
    } else {
      this.attrInclude = _.isArray(values)
        ? [...(this.attrInclude || []), ...values]
        : [...(this.attrInclude || []), values];
    }
  }

  /**
   * Исключить указанные имена атрибутов для мутации
   */
  setAttrExclude(values?: RegExp[] | RegExp) {
    if (!values) {
      this.attrExclude = TAG_MUTATION.EXCLUDE.ATTRIBUTES.all;
    } else {
      this.attrExclude = _.isArray(values)
        ? [...(this.attrExclude || []), ...values]
        : [...(this.attrExclude || []), values];
    }
  }

  /**
   * Включить указанные значения для мутации (по умолчанию - все)
   */
  setValInclude(values?: RegExp[] | RegExp) {
    if (!values) {
      this.valInclude = TAG_MUTATION.INCLUDE.VALUES.all;
    } else {
      this.valInclude = _.isArray(values)
        ? [...(this.valInclude || []), ...values]
        : [...(this.valInclude || []), values];
    }
  }

  /**
   * Исключить указанные значение для мутации.
   */
  setValExclude(values?: RegExp[] | RegExp) {
    if (!values) {
      this.valExclude = TAG_MUTATION.EXCLUDE.VALUES.all;
    } else {
      this.valExclude = _.isArray(values)
        ? [...(this.valExclude || []), ...values]
        : [...(this.valExclude || []), values];
    }
  }

  /**
   * Проверка пути атрибута для мутации
   *
   * @param path путь параметра
   * @returns результат проверки
   */
  private allowAttrMutation(path: string): boolean {
    if (this.attrInclude?.find((regExp) => regExp.exec(path))) return true;
    if (this.attrExclude?.find((regExp) => regExp.exec(path))) return false;

    return true;
  }

  /**
   * Проверка и преобразование строки
   * Проверяет на наличие спецсимволов "<%" и "%>" и возвращает если истина;
   * Проверяет на соответствие регулярному выражению из @alias this.regExp и возвращает, если истина, обрамляя в "<%" и "%>";
   * @param srcString
   * @returns
   */
  private checkString(srcString: string) {
    if (this.valInclude?.find((regExp) => regExp.exec(srcString))) {
      return /<%.*%>/.exec(srcString) ? srcString : `<%${srcString}%>`;
    }
    if (this.valExclude?.find((regExp) => regExp.exec(srcString))) return;
    if (/<%.*%>/.exec(srcString)) return srcString;
  }

  /**
   * Применить значения переменых к строке.
   * Если она содержит шаблон lodash Template,
   * то есть обозначенную <%= ... %> строку - заполняем значениями из контекста
   * @returns Мутированная строка (заполненная значениями)
   */
  applyStringVariables(srcString: string, path?: string) {
    const mutantString = this.checkString(srcString);
    const allowed = path ? this.allowAttrMutation(path) : true;
    if (mutantString && allowed) {
      try {
        const compiled = _.template(mutantString);
        const res = compiled(this.context);
        return res;
      } catch {
        console.log(
          `${this.srcObject?.collection} ${this.srcObject?.id} value ${srcString} - rejected`
        );
        return srcString;
      }
    }
    return srcString;
  }

  /**
   * Рекурсивно применяет переменные на объекте
   * @template T тип исходного объекта
   * @param srcObj исходный объект
   * @param path путь до текущего объекта в контексте, для проверки на включение/исключение
   * @param attributes массив атрибутов, которые нужно обработать. Если не передан, обработать все
   * @returns объект с замененными значениями
   */
  applyObjectVariables<T>(srcObj: T, path?: string, attributes?: string[]): T {
    if (!_.isObject(srcObj) || _.isArray(srcObj)) return srcObj;
    if (!attributes) {
      return _.reduce(
        srcObj,
        (r, v, k) => {
          const attributePath = path ? `${path}.${k}` : k;
          const allowed = this.allowAttrMutation(attributePath);
          const mutantValue = allowed
            ? this.applyAttributeVariables(
                v as TBaseDataObject[''],
                attributePath
              )
            : v;
          return { ...r, [k]: mutantValue };
        },
        {} as T
      );
    }
    if (!attributes.length) return srcObj;

    const mutantObj = _.cloneDeep(srcObj);
    for (const srcAttr of Object.keys(srcObj)) {
      const schemeAttr = attributes.find(
        (attr) => attr.split('.')[0] === srcAttr
      );
      if (schemeAttr) {
        const srcValue = _.get(srcObj, schemeAttr);
        const mutantValue = this.applyAttributeVariables(
          srcValue as TBaseDataObject[''],
          schemeAttr
        );
        _.set(mutantObj, schemeAttr, mutantValue);
      }
    }
    return mutantObj;
  }

  private applyAttributeVariables = (
    fieldValue: TBaseDataObject[''],
    path: string
  ): typeof fieldValue => {
    if (_.isArray(fieldValue)) {
      const mutantArray = fieldValue.map((item, key) => {
        const itemPath = `${path}.${key}`;
        const allowed = this.allowAttrMutation(itemPath);
        return allowed ? this.applyAttributeVariables(item, itemPath) : item;
      });
      return mutantArray as typeof fieldValue;
    }
    if (_.isObject(fieldValue)) {
      // if (_.has(fieldValue, 'id')) return fieldValue;
      const mutantObject = this.applyObjectVariables(fieldValue, path);
      return mutantObject;
    }
    if (_.isString(fieldValue)) {
      const mutantString = this.applyStringVariables(fieldValue, path);
      return mutantString;
    }
    return fieldValue;
  };

  /**
   * Возвращает новый объект, полученный из исходного объекта с примененными к нему мутациями
   * @param srcObject - исходный объект
   * @returns объект с примененными к нему мутациями или исходный объект, если мутации невозможны
   */
  mutate(srcObject?: TAbstractStoreObject): TAbstractStoreObject | undefined {
    if (
      !srcObject ||
      !_.isObject(srcObject) ||
      _.isArray(srcObject) ||
      MUTATION_OBJECT.REQUIRED.filter((key) => !_.has(srcObject, key)).length
    ) {
      console.log(
        `Тип объекта не поддерживается функцией мутации. отсутствует один или несколько обязательных атрибутов: ${MUTATION_OBJECT.REQUIRED.join(
          '; '
        )}`,
        srcObject
      );
      return srcObject;
    }

    this.srcObject = _.cloneDeep(srcObject);

    if (!srcObject.mutationSchema)
      return this.applyObjectVariables(_.cloneDeep(srcObject));

    if (!srcObject.mutationSchema.attributes) return srcObject;

    return this.applyObjectVariables(
      srcObject,
      undefined,
      srcObject.mutationSchema.attributes
    );
  }
}
