import { TemplateElement } from '../TemplateElement/TemplateElement';
import { TChildren } from './types';
import _ from 'lodash';
import { TTemplateElem } from '../TemplateElement/types';

export const CustomChildren: React.FC<TChildren> = ({
  group,
  mutationSchema,
  contexts,
}) => {
  const children = contexts.template?.children?.filter(
    (item) => item.group === group
  );
  if (!children || !children.length) return null;
  return (
    <>
      {children.map((templateElement, i) => {
        if (!mutationSchema?.path)
          return (
            <TemplateElement key={i} {...{ ...templateElement, contexts }} />
          );
        const attributesByPath = _.reduce(
          templateElement.mutationSchema?.attributes,
          (r, v) => {
            const currentPathRegExp = new RegExp(
              '/^' +
                [...(templateElement.mutationSchema?.path || []), ''].join() +
                '/'
            );
            if (currentPathRegExp.test(v)) {
              const currentPathAttribute = v
                .replace(currentPathRegExp, '')
                .replace(/^template\.(\[\d.+\]\.)?/, '');
              return [
                ...r,
                `${mutationSchema?.path?.join('.')}.${currentPathAttribute}`,
              ];
            }
            return [...r];
          },
          [] as string[]
        );

        const newMutationSchema: TTemplateElem['mutationSchema'] = {
          path: mutationSchema?.path,
          attributes: attributesByPath,
          stores: mutationSchema.stores,
        };

        return (
          <TemplateElement
            key={i}
            {...{
              ...templateElement,
              mutationSchema: newMutationSchema,
              contexts,
            }}
          />
        );
      })}
    </>
  );
};
