import { TMutationContext } from '../types';
import { useLocation, useParams } from 'react-router-dom';
import { getContentCollections } from '../../../Store/Slices/Content/getContentCollections';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getMyUser } from '../../../Store/Slices/Content/getMyUser';
import {
  selectCollections,
  selectContentItems,
  selectMyUser,
} from '../../../Store/Slices/Content/selectors';
import {
  selectCustomUIStates,
  selectJWT,
  selectLocale,
  selectToasts,
} from '../../../Store/Slices/UI/selectors';
import { selectGlobalVariables } from '../../../Store/Slices/Structure/selectors';
import { constructMutatorContexts } from '../Helpers/constructMutatorContexts';

export const useMutationContexts = (
  current: TMutationContext['current'] | undefined
): TMutationContext => {
  const location = useLocation();
  const params = useParams();

  const locale = useSelector(selectLocale);
  const states = useSelector(selectCustomUIStates);
  const myUser = useSelector(selectMyUser);
  const contentItems = useSelector(selectContentItems);
  const user = useMemo(
    () => (myUser ? getMyUser(myUser, contentItems) : undefined),
    [contentItems, myUser]
  );
  const token = useSelector(selectJWT);
  const variables = useSelector(selectGlobalVariables);

  const toastsNorm = useSelector(selectToasts);
  const toasts = useMemo(() => Object.values(toastsNorm), [toastsNorm]);

  const normCollections = useSelector(selectCollections);
  const collections = useMemo(
    () => getContentCollections(normCollections, contentItems),
    [contentItems, normCollections]
  );

  return constructMutatorContexts(
    location,
    params,
    locale,
    states,
    token,
    user,
    current,
    variables,
    toasts,
    collections
  );
};
