import { TSliceContent } from './sliceContent';
import { TCollection } from '../types';
import _ from 'lodash';
import { schemaNormalize } from '../../Normalizers/schemaNormalize';
import { denormalize } from 'normalizr';

export const getContentCollections = (
  normCollections: TSliceContent['collections'],
  contentItems: TSliceContent['items']
): Record<string, TCollection> => {
  return _.reduce(
    normCollections,
    (r, v, k) => {
      const type = v.itemsType as keyof TSliceContent['items'];
      if (!type) return { ...r };
      const normItems = contentItems[type];
      if (!normItems) return { ...r };
      const normSchema = schemaNormalize(type);
      if (!normSchema) return { ...r };
      const items = v.items?.length
        ? Object.values(normItems)
            .filter((item) => v.items?.includes(item.id))
            .map((item) => denormalize(item, normSchema(), contentItems))
        : [];
      return {
        ...r,
        [k]: {
          ...v,
          items,
        },
      };
    },
    {}
  );
};
