import _ from 'lodash';
import { TCustomTemplate } from './types';
import { TTemplateElem } from '../TemplateElement/types';
import { useTemplate } from './helpers/useTemplate';
import { TMutationContext } from '../../../../Library/Mutator/types';
import { TemplateElements } from '../TemplateElement/Components/TemplateElements';
import { updateElemVariables } from './helpers/updateElemVariables';

/**
 * Компонент макета с дочерними элементами
 *
 * @param templateElement - объект макета
 * @returns - React-компоненты дочерних элементов
 */
export const CustomTemplate: React.FC<TCustomTemplate> = ({
  id,
  name,
  variables,
  variablesDepth,
  contexts,
  childrenPrototype,
}) => {
  const template = useTemplate(id, name);

  if (!template || template.excluded) return null;

  const templateContext = contexts?.template;

  const children = _.isArray(childrenPrototype)
    ? (
        childrenPrototype.filter((item) => _.isObject(item)) as TTemplateElem[]
      ).map((item, i) => ({
        ...item,
        mutationSchema: {
          ...template.mutationSchema,
          path: ['template', `[${i}]`],
        },
      }))
    : _.isObject(childrenPrototype)
    ? [
        {
          ...childrenPrototype,
          mutationSchema: {
            ...template.mutationSchema,
            path: ['template'],
          },
        },
      ]
    : [];

  const newVariablesData = updateElemVariables(
    { variables, variablesDepth },
    {
      variables: templateContext?.variables,
      variablesDepth: templateContext?.variablesDepth,
    }
  );

  const newContexts: TMutationContext['current'] = {
    ...contexts,
    template: {
      id,
      name,
      path: [...(templateContext?.path || []), id || name || 'unknown'],
      variables: newVariablesData.variables,
      variablesDepth: newVariablesData.variablesDepth,
      children,
    },
  };

  return (
    <TemplateElements
      elements={template.template}
      schema={{
        ...template.mutationSchema,
        path: ['template'],
      }}
      contexts={newContexts}
    />
  );
};
