import { TResponseObject } from '../Types/typesFetch';
import { TSiteActionProps } from '../Types/typesGlobal';

/** Адрес сервера  API */
export const API_URL_PREFIX =
  document.location.port === '3000'
    ? `http://${document.location.hostname}:9000`
    : '';

export const API_ENDPOINTS = {
  collections: '/api/collections',
  items: '/api/items',
  init: '/api/init',
  login: '/api/auth/local',
  register: '/api/auth/local/register',
  'forgot-password': '/api/auth/forgot-password',
  'reset-password': '/api/auth/reset-password',
};

/** Локаль по-умолчанию */
export const LOCALE_DEFAULT = 'ru-RU';

/** Экшны при ошибках запросов */
export const fetchErrorActions: (Partial<TResponseObject['error']> & {
  actions: TSiteActionProps[];
})[] = [
  {
    message: 'Missing or invalid credentials',
    status: 401,
    actions: [
      {
        name: 'logOut',
      },
    ],
  },
];
