import {
  Accordion,
  Alert,
  Badge,
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Carousel,
  CloseButton,
  Col,
  Collapse,
  Dropdown,
  DropdownButton,
  DropdownToggle,
  Figure,
  Form,
  InputGroup,
  ListGroup,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Overlay,
  Pagination,
  Placeholder,
  ProgressBar,
  Row,
  Spinner,
  Tab,
  Table,
  Tabs,
  Toast,
} from 'react-bootstrap';
import { CustomChildren } from '../../CustomChildren/CustomChildren';
import { CustomContexts } from '../../CustomContexts/CustomContexts';
import { CustomForm } from '../../CustomForm/CustomForm';
import { CustomFormGroups } from '../../CustomFormGroups/CustomFormGroups';
import { CustomIcon } from '../../CustomIcon/CustomIcon';
import { CustomInputImage } from '../../CustomInputImage/CustomInputImage';
import { CustomInputSimple } from '../../CustomInputSimple/CustomInputSimple';
import { CustomLink } from '../../CustomLink/CustomLink';
import { CustomMarkdown } from '../../CustomMarkdown/CustomMarkdown';
import { CustomTemplate } from '../../CustomTemplate/CustomTemplate';
import { CustomTest } from '../../CustomTest/CustomTest';
import { CustomToasts } from '../../CustomToasts/CustomToasts';
import { CustomInputBoolean } from '../../CustomInputBoolean/CustomInputBoolean';
import { CustomGroup } from '../../CustomGroup/CustomGroup';
import { CustomCropper } from '../../CustomInputImage/CustomCropper';
import { CustomHTML } from '../../CustomHTML/CustomHTML';
import { CustomCollection } from '../../CustomCollection/CustomCollection';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const templateElements: Record<string, React.FC<any> | undefined> = {
  // Кастомные компоненты

  Test: CustomTest,
  Contexts: CustomContexts,
  Icon: CustomIcon,
  Template: CustomTemplate,
  Group: CustomGroup,
  Children: CustomChildren,
  Link: CustomLink,
  Markdown: CustomMarkdown,
  FormContext: CustomForm,
  ToastsContext: CustomToasts,
  InputSimpleContext: CustomInputSimple,
  InputImageContext: CustomInputImage,
  InputBooleanContext: CustomInputBoolean,
  FormGroupsContext: CustomFormGroups,
  CustomCropper: CustomCropper,
  TemplateHTML: CustomHTML,
  CollectionContext: CustomCollection,

  // React-bootstrap компоненты

  Accordion: Accordion,
  Alert: Alert,
  Badge: Badge,
  Button: Button,
  ButtonGroup: ButtonGroup,
  Card: Card,
  Carousel: Carousel,
  Col: Col,
  Collapse: Collapse,
  Dropdown: Dropdown,
  DropdownToggle: DropdownToggle,
  DropdownButton: DropdownButton,
  Figure: Figure,
  Form: Form,
  InputGroup: InputGroup,
  ListGroup: ListGroup,
  Modal: Modal,
  Navbar: Navbar,
  Nav: Nav,
  Offcanvas: Offcanvas,
  Overlay: Overlay,
  Placeholder: Placeholder,
  ProgressBar: ProgressBar,
  Row: Row,
  Spinner: Spinner,
  Table: Table,
  Tabs: Tabs,
  Tab: Tab,
  Toast: Toast,
  Breadcrumb: Breadcrumb,
  CloseButton: CloseButton,
  NavDropdown: NavDropdown,
  Pagination: Pagination,
};
