import _ from 'lodash';
import { TResponseObject } from '../../../Types/typesFetch';
import { TBaseDataObject } from '../../../Types/typesGlobal';
import { fetchApi } from './fetchApi';

export const fetchOtherPages = async (
  url: string,
  parameters: Partial<TBaseDataObject>,
  firstResp?: TResponseObject
): Promise<TResponseObject | undefined> => {
  const firstMeta = firstResp?.meta;
  if (
    firstResp?.error ||
    !firstMeta ||
    !firstMeta.pagination.pageCount ||
    !firstMeta.pagination.page ||
    firstMeta.pagination.page >= firstMeta.pagination.pageCount
  )
    return firstResp;

  const iterations = [
    ...Array(firstMeta.pagination.pageCount - firstMeta.pagination.page),
  ].map((u, i) => i + 1);

  let data = _.compact([
    ...(_.isArray(firstResp.data) ? firstResp.data : [firstResp.data]),
  ]);

  let meta = {} as TResponseObject['meta'];

  for (const iteration of iterations) {
    const newResponse = await fetchApi({
      url,
      method: 'GET',
      parameters: {
        ...parameters,
        pagination: {
          page: firstMeta.pagination.page + iteration,
          pageSize: firstMeta.pagination.pageSize,
        },
      },
    });

    if (newResponse.error) return newResponse;

    const newData = newResponse.data
      ? _.isArray(newResponse.data)
        ? newResponse.data
        : [newResponse.data]
      : [];

    data = [...data, ...newData];
    meta = newResponse.meta;
  }
  return {
    data,
    meta,
  };
};
