import { TItemType } from '../../../../../Types/typesUnit';

export const AUTH_FORM_TYPES: TItemType[] = [
  {
    id: 'login',
    name: 'login',
    description: 'Логин',
    type: 'access',
    attributes: [
      {
        name: 'identifier',
        type: 'attributes',
        inputType: 'email',
        required: true,
        validationSchema: [
          {
            isValid:
              '<% return !current.input?.value || /^[\\w\\d\\+-\\._%]+@[\\w\\d\\-]+.+.\\w{2,4}$/i.test(current.input.value) %>',
            feedback: 'Формат поля mailbox@domain.ru',
          },
        ],
      },
      {
        name: 'password',
        type: 'attributes',
        inputType: 'password',
        required: true,
      },
    ],
  },
  {
    id: 'register',
    name: 'register',
    description: 'Регистрация',
    type: 'access',
    attributes: [
      {
        name: 'email',
        type: 'attributes',
        inputType: 'email',
        required: true,
        validationSchema: [
          {
            isValid:
              '<% return !current.input?.value || /^[\\w\\d\\+-\\._%]+@[\\w\\d\\-]+.+.\\w{2,4}$/i.test(current.input.value) %>',
            feedback: 'Формат поля mailbox@domain.ru',
          },
        ],
      },
      {
        name: 'username',
        type: 'attributes',
        inputType: 'text',
      },
      {
        name: 'password',
        type: 'attributes',
        inputType: 'password',
        required: true,
        validationSchema: [
          {
            isValid:
              '<% return !current.form?.inputs.password?.value || !current.form?.inputs.passwordConf?.value || current.form?.inputs.password?.value === current.form?.inputs.passwordConf?.value %>',
            feedback: 'Значения не совпадают',
          },
          {
            isValid: '<% return /^.{6,}$/.test(current.input?.value) %>',
            feedback: 'не менее 6 символов',
          },
          {
            isValid:
              '<% return /^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*$/.test(current.input?.value) %>',
            feedback: 'должен содержать a-z, A-Z, 0-9 ',
          },
        ],
      },
      {
        name: 'passwordConf',
        type: 'attributes',
        inputType: 'password',
        required: true,
        validationSchema: [
          {
            isValid:
              '<% return !current.form?.inputs.password?.value || !current.form?.inputs.passwordConf?.value || current.form?.inputs.password?.value === current.form?.inputs.passwordConf?.value %>',
            feedback: 'Значения не совпадают',
          },
          {
            isValid: '<% return /^.{6,}$/.test(current.input?.value) %>',
            feedback: 'не менее 6 символов',
          },
          {
            isValid:
              '<% return /^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*$/.test(current.input?.value) %>',
            feedback: 'должен содержать a-z, A-Z, 0-9 ',
          },
        ],
      },
      {
        name: 'policyAgreement',
        type: 'attributes',
        inputType: 'checkbox',
        required: true,
      },
    ],
  },
  {
    id: 'forgot-password',
    name: 'forgot-password',
    description: 'Восстановление пароля',
    type: 'access',
    attributes: [
      {
        name: 'email',
        type: 'attributes',
        inputType: 'email',
        required: true,
        validationSchema: [
          {
            isValid:
              '<% return !current.input?.value || /^[\\w\\d\\+-\\._%]+@[\\w\\d\\-]+.+.\\w{2,4}$/i.test(current.input.value) %>',
            feedback: 'Формат поля mailbox@domain.ru',
          },
        ],
      },
    ],
  },
  {
    id: 'reset-password',
    name: 'reset-password',
    description: 'Смена пароля',
    type: 'access',
    attributes: [
      {
        name: 'password',
        type: 'attributes',
        inputType: 'password',
        required: true,
        validationSchema: [
          {
            isValid:
              '<% return !current.form?.inputs.password?.value || !current.form?.inputs.passwordConf?.value || current.form?.inputs.password?.value === current.form?.inputs.passwordConf?.value %>',
            feedback: 'Значения не совпадают',
          },
          {
            isValid: '<% return /^.{6,}$/.test(current.input?.value) %>',
            feedback: 'не менее 6 символов',
          },
          {
            isValid:
              '<% return /^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*$/.test(current.input?.value) %>',
            feedback: 'должен содержать a-z, A-Z, 0-9 ',
          },
        ],
      },
      {
        name: 'passwordConfirmation',
        type: 'attributes',
        inputType: 'password',
        required: true,
        validationSchema: [
          {
            isValid:
              '<% return !current.form?.inputs.password?.value || !current.form?.inputs.passwordConf?.value || current.form?.inputs.password?.value === current.form?.inputs.passwordConf?.value %>',
            feedback: 'Значения не совпадают',
          },
          {
            isValid: '<% return /^.{6,}$/.test(current.input?.value) %>',
            feedback: 'не менее 6 символов',
          },
          {
            isValid:
              '<% return /^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*$/.test(current.input?.value) %>',
            feedback: 'должен содержать a-z, A-Z, 0-9 ',
          },
        ],
      },
      {
        name: 'code',
        type: 'attributes',
        inputType: 'text',
      },
    ],
  },
];
