import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';
import { TGetDocxProps, getDocx } from '../../getDocx';

export const ActionGetDOCX = (
  arg?: TSiteActionProps['argument'],
  ID?: string
) => {
  if (
    arg &&
    _.has(arg, 'actionID') &&
    _.has(arg, 'templateUrl') &&
    _.has(arg, 'attributes') &&
    _.has(arg, 'fileName')
  ) {
    getDocx({ ...(arg as unknown as TGetDocxProps), actionID: ID });
  } else {
    console.log('wrong argument for getDOCX action!');
  }
};
