import _ from 'lodash';
import { RootState } from '../../Store';

export const selectSIteSets = (state: RootState) =>
  state.sliceStructure.items.siteSets;
export const selectSitePages = (state: RootState) =>
  state.sliceStructure.items.sitePages;
export const selectItemTemplates = (state: RootState) =>
  state.sliceStructure.items.itemTemplates;
export const selectIcons = (state: RootState) =>
  state.sliceStructure.items.icons;
export const selectAlerts = (state: RootState) =>
  state.sliceStructure.items.alerts;
export const selectItemTypes = (state: RootState) =>
  state.sliceStructure.items.itemTypes;
export const selectGlobalVariables = (state: RootState) => {
  const sets = state.sliceStructure.items.siteSets;
  const locale = state.sliceUI.locale;
  return _.find(sets, (v) => v.locale === locale)?.globalVariables;
};
