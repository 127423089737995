import _ from 'lodash';
import { Attributes, createElement } from 'react';
import { TTemplateElem } from '../types';
import {
  CONTEXT_UPDATERS,
  DOM_CREATOR_EXCLUDE_ATTRIBUTES,
  VOID_ELEMENTS,
} from '../constants';
import { Children } from './Children';
import { TMutationContext } from '../../../../../Library/Mutator/types';

/**
 * Компонент для рендера пользовательских элементов макета.
 * @param component Компонент, который будет использован для рендера элемента.
 * @param templateElement Элемент макета, который нужно отобразить.
 * @returns JSX-элемент, который будет отображен на странице.
 */
export const CustomElement: React.FC<{
  component: React.FC;
  templateElement: TTemplateElem & { contexts: Partial<TMutationContext> };
}> = ({ component, templateElement }) => {
  const isContextUpdater = !!CONTEXT_UPDATERS.find(
    (item) => item === templateElement.tag
  );
  const attrExclude = [
    ...DOM_CREATOR_EXCLUDE_ATTRIBUTES.CUSTOM,
    ...(isContextUpdater ? ['children'] : []),
  ];
  const elementProps = {
    ..._.omit(templateElement, attrExclude),
    ...(isContextUpdater
      ? { childrenPrototype: templateElement.children }
      : {}),
  } as Attributes;

  try {
    return createElement(
      component,
      elementProps,
      !VOID_ELEMENTS.includes(templateElement.tag) ? (
        <Children {...templateElement} />
      ) : undefined
    );
  } catch {
    console.log(
      `Проверьте элемент макета с тэгом ${templateElement.tag} и ID ${templateElement.id}`
    );
  }
  return null;
};
