import { cleanupContent } from '../../../Store/Slices/Content/sliceContent';
import { cleanupStructure } from '../../../Store/Slices/Structure/sliceStructure';
import { cleanupUI, cleanupJWT } from '../../../Store/Slices/UI/sliceUI';
import { store } from '../../../Store/Store';

/**
 * выйти из профиля (разлогиниться)
 */
export const ActionLogOut = () => {
  store.dispatch(cleanupJWT());
  store.dispatch(cleanupUI());
  store.dispatch(cleanupContent());
  store.dispatch(cleanupStructure());
};
