import _ from 'lodash';
import { TSliceUI, setCustomState } from '../../../Store/Slices/UI/sliceUI';
import { store } from '../../../Store/Store';
import { TBaseDataObject } from '../../../Types/typesGlobal';

type TArgument = {
  name: string;
  value?: TSliceUI['customStates'][''];
  timeout?: number;
};

export const ActionSetCustomState = (
  arg?: string | number | boolean | TBaseDataObject
) => {
  if (!_.get(arg, 'name')) {
    console.log('Check argument format { name: string, value?: any }');
    return;
  }

  store.dispatch(setCustomState(arg as TArgument));
};
