export const getResizedImage = (
  initialDataUrl: string,
  width: number,
  height: number,
  type: string
): Promise<{
  scaledWidth: number;
  scaledHeight: number;
  scaledDataUrl: string;
}> => {
  const image = new Image();
  image.src = initialDataUrl;
  return new Promise((resolve) => {
    image.onload = () => {
      const scaleFactor = Math.min(width / image.width, height / image.height);
      const scaledWidth = image.width * scaleFactor;
      const scaledHeight = image.height * scaleFactor;
      const canvas = document.createElement('canvas');
      canvas.width = scaledWidth;
      canvas.height = scaledHeight;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(image, 0, 0, scaledWidth, scaledHeight);
      const scaledDataUrl = canvas.toDataURL(type);
      resolve({ scaledWidth, scaledHeight, scaledDataUrl });
    };
  });
};
