import { TemplateElements } from '../TemplateElement/Components/TemplateElements';
import { updateElemVariables } from '../CustomTemplate/helpers/updateElemVariables';
import { TCustomGroup } from './types';

export const CustomGroup: React.FC<TCustomGroup> = ({
  variables,
  variablesDepth,
  childrenPrototype,
  contexts,
  mutationSchema,
}) => {
  const templateContext = contexts.template;
  const newVariablesData = updateElemVariables(
    { variables, variablesDepth },
    {
      variables: templateContext?.variables,
      variablesDepth: templateContext?.variablesDepth,
    },
    true
  );
  const newContexts = {
    ...contexts,
    template: {
      path: [],
      children: [],
      ...contexts.template,
      variables: newVariablesData.variables,
      variablesDepth: newVariablesData.variablesDepth,
    },
  };

  return (
    <TemplateElements
      elements={childrenPrototype}
      schema={{
        ...mutationSchema,
        path: [...(mutationSchema?.path || []), 'children'],
      }}
      contexts={newContexts}
    />
  );
};
