import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';

/**
 * Перенаправить на указаный в props.argument адрес (присвоится window.location.href)
 */
export const ActionRedirectTo = (arg?: TSiteActionProps['argument']) => {
  if (arg && _.isString(arg)) {
    window.location.href = arg;
  } else {
    console.log('wrong argument for redirectTo action!');
  }
};
