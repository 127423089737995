import { RootState } from '../../Store';

export const selectLocale = (state: RootState) => state.sliceUI.locale;
export const selectCustomUIStates = (state: RootState) =>
  state.sliceUI.customStates;
export const selectMeID = (state: RootState) => state.sliceUI.meID;
export const selectJWT = (state: RootState) => state.sliceUI.jwt;
export const selectNavigateTo = (state: RootState) => state.sliceUI.navigateTo;
export const selectToasts = (state: RootState) => state.sliceUI.toasts;
export const selectAuthStates = (state: RootState) => state.sliceUI.auth;
