import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';
import { setNavigateTo } from '../../../Store/Slices/UI/sliceUI';
import { store } from '../../../Store/Store';

type TNavigateArg =
  | string
  | { url: string; action?: TSiteActionProps | TSiteActionProps[] };

/**
 * Записать в state.redirectTo указаный в props.argument адрес
 */
export const ActionNavigateTo = (arg: TSiteActionProps['argument']) => {
  if (_.isString(arg) || _.has(arg, 'url')) {
    const argument = arg as TNavigateArg;
    const url = _.isString(argument) ? argument : argument.url;
    const action = _.isString(argument) ? undefined : argument.action;
    const isLocal = !/^.+:/.test(url);
    if (isLocal) {
      store.dispatch(setNavigateTo({ url, action }));
    } else {
      window.open(url, '_blank');
    }
  } else {
    console.log('wrong argument for navigateTo action!');
  }
};
