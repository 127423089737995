import { schema } from 'normalizr';

/**
 * Нормализация иконок
 *
 * @param depth - глубина нормализации
 * @returns схема
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaNormalizeIcons = (): schema.Entity => {
  return new schema.Entity('icons');
};

export default schemaNormalizeIcons;
