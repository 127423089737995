import { TCustomToasts } from './types';
import { TemplateElements } from '../TemplateElement/Components/TemplateElements';

export const CustomToasts: React.FC<TCustomToasts> = ({
  childrenPrototype,
  mutationSchema,
  contexts,
}) => {
  return (
    <TemplateElements
      elements={childrenPrototype}
      schema={{
        ...mutationSchema,
        path: [...(mutationSchema?.path || []), 'children'],
      }}
      contexts={contexts}
    />
  );
};
