import _ from 'lodash';
import { Mutator } from '../../../../../Library/Mutator/Mutator';
import { TTemplateElem } from '../types';
import { TSiteActionProps } from '../../../../../Types/typesGlobal';
import { ReactEventHandler } from 'react';
import { mutantTextChildren } from './mutantTextChildren';
import { TAG_MUTATION } from '../constants';
import { execMultiplyActions } from '../../../../../Library/Action/Helpers/execMultiplyActions';

/**
 * Мутатор элемента макета
 * Применяет мутации к элементу макета, с учетом параметров include-exclude,
 * Дочерние объекты - мутирует только если они представлены шаблонной строкой
 * Атрибутам событий - применяет соответствующие экшны (согласно указанному объекту типа TSiteActionProps) либо функции по результату мутации шаблонной строки
 * Предотвращает циклическую вложенность шаблонов
 *
 * @param mutator - мутатор
 * @param templateElement - элемент макета
 * @returns - мутированный элемент макета
 */
export const mutantTemplateElement = (
  mutator: Mutator,
  templateElement: TTemplateElem,
  path?: string[]
) => {
  // Добавляю черные и белые списки мутации
  mutator.setAttrExclude([
    ...(templateElement.mutationProps?.attrExclude?.map((v) => new RegExp(v)) ||
      []),
    ...(TAG_MUTATION.EXCLUDE.ATTRIBUTES[templateElement.tag] || []),
  ]);
  mutator.setAttrInclude([
    ...(templateElement.mutationProps?.attrInclude?.map((v) => new RegExp(v)) ||
      []),
    ...(TAG_MUTATION.INCLUDE.ATTRIBUTES[templateElement.tag] || []),
  ]);
  mutator.setValExclude([
    ...(templateElement.mutationProps?.valExclude?.map((v) => new RegExp(v)) ||
      []),
    ...(TAG_MUTATION.EXCLUDE.VALUES[templateElement.tag] || []),
  ]);
  mutator.setValInclude([
    ...(templateElement.mutationProps?.valInclude?.map((v) => new RegExp(v)) ||
      []),
    ...(TAG_MUTATION.INCLUDE.VALUES[templateElement.tag] || []),
  ]);

  // Применяю мутации
  const mutantElement = mutator.applyObjectVariables(templateElement);

  // if (templateItem.id === '1') console.log(templateItem, mutator.attrExclude);

  // Применяю дочерние шаблоны и хендлеры
  const updatedElement = _.reduce(
    mutantElement,
    (r, v, k) => {
      if (k === 'children') {
        const mutantChildren = mutantTextChildren(
          mutator,
          v as TTemplateElem['children']
        );
        return {
          ...r,
          [k]: _.isArray(mutantChildren)
            ? _.flatten(mutantChildren)
            : mutantChildren,
        } as TTemplateElem;
      }
      if (/^on[A-Z].+/.test(k) && k !== 'onLoad') {
        const handler: ReactEventHandler<HTMLElement> = (e) => {
          if (e) e.preventDefault();
          if (!v) return;
          if (_.isArray(v)) {
            execMultiplyActions(v as TSiteActionProps[], mutator);
          } else {
            execMultiplyActions([v as TSiteActionProps], mutator);
          }
        };
        return { ...r, [k]: _.isFunction(v) ? v : handler } as TTemplateElem;
      }
      return { ...r, [k]: v };
    },
    {} as TTemplateElem
  );

  // Проверяю на циклическую вложенность
  if (
    updatedElement.tag === 'Template' &&
    path?.find((item) => item === updatedElement.id)
  ) {
    console.log(
      `You can't add an Template (id: ${updatedElement.id} in ${path.join(
        '->'
      )}) to its own children!`
    );
    return null;
  }

  return updatedElement;
};
