import _ from 'lodash';
import { schemaNormalize } from '../../Normalizers/schemaNormalize';
import { TSliceStructure } from './sliceStructure';
import { denormalize } from 'normalizr';
import { TSitePage } from '../../../Types/typesStructure';

export const getSitePages = (
  pagesNorm: TSliceStructure['items']['sitePages'],
  templates: TSliceStructure['items']['itemTemplates'],
  locale: string
): TSitePage[] => {
  const normSchema = schemaNormalize('sitePages', true);
  if (!normSchema) return [];
  return _.filter(pagesNorm, (page) => page.locale === locale).map((page) =>
    denormalize(page, normSchema(), { itemTemplates: templates })
  );
};
