import { RootState } from '../../Store';

export const selectUsers = (state: RootState) => state.sliceContent.items.users;
export const selectMyUser = (state: RootState) =>
  state.sliceContent.items.users[state.sliceUI.meID || 'unknown'];
export const selectUnits = (state: RootState) => state.sliceContent.items.units;
export const selectContentItems = (state: RootState) =>
  state.sliceContent.items;
export const selectCollections = (state: RootState) =>
  state.sliceContent.collections;
export const selectItemSubmits = (state: RootState) =>
  state.sliceContent.itemSubmits;
