import { schema } from 'normalizr';

/**
 * Нормализация шаблонов
 * @param depth - глубина нормализации
 * @returns схема
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schemaNormalizeItemTemplates = (): schema.Entity => {
  return new schema.Entity('itemTemplates');
};

export default schemaNormalizeItemTemplates;
