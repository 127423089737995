import { createSlice } from '@reduxjs/toolkit';
import {
  authDataSubmitSucceed,
  collectionItemSubmitPending,
  collectionItemSubmitRejected,
  collectionItemSubmitSucceed,
  getCollectionRequestPending,
  getCollectionRequestRejected,
  getCollectionRequestSucceed,
  setInitialState,
} from '../../Actions/actionsExtra';
import { TCollectionNorm } from '../types';
import _ from 'lodash';
import { TUserNorm } from '../../../Types/typesUser';
import { TUnitNorm } from '../../../Types/typesUnit';
import { TResponseObject } from '../../../Types/typesFetch';
import { TFetchStatus } from '../../../Types/typesGlobal';

export type TCollectionItemSubmitStatus = {
  id: string;
  status: TFetchStatus;
  error?: TResponseObject['error'];
  itemsType: 'users' | 'units';
  items?: string;
};

export type TSliceContent = {
  collections: Record<string, TCollectionNorm>;
  items: {
    users: { [key: string]: TUserNorm };
    units: { [key: string]: TUnitNorm };
  };
  itemSubmits: { [key: string]: TCollectionItemSubmitStatus };
};
const initialState: TSliceContent = {
  collections: {},
  itemSubmits: {},
  items: {
    users: {},
    units: {},
  },
};

const sliceContent = createSlice({
  name: 'content',
  initialState,

  extraReducers: (builder) => {
    builder.addCase(setInitialState, () => initialState);

    builder.addCase(getCollectionRequestPending, (state, { payload }) => {
      state.collections = _.merge(state.collections, {
        [payload.id]: {
          id: payload.id,
          name: payload.name,
          fetchStatus: 'progress',
        },
      });
    });

    builder.addCase(getCollectionRequestRejected, (state, { payload }) => {
      state.collections = _.merge(state.collections, {
        [payload.id]: {
          id: payload.id,
          fetchStatus: 'error',
        },
      });
    });

    builder.addCase(getCollectionRequestSucceed, (state, { payload }) => {
      const { id, itemsType, items, pagination } = payload;
      const storedCollectionItemIDs = state.collections[id]?.items || [];
      const collectionItemIDs: string[] = Object.values(
        items[itemsType] || []
      ).map((item) => item.id);

      state.collections = _.merge(state.collections, {
        [payload.id]: {
          id: payload.id,
          fetchStatus: 'success',
          itemsType,
          items: _.uniq([...collectionItemIDs, ...storedCollectionItemIDs]),
          pagination,
          error: undefined,
        },
      });
      state.items = _.merge(state.items, items);
    });

    builder.addCase(collectionItemSubmitPending, (state, { payload }) => {
      state.itemSubmits = _.merge(state.itemSubmits, {
        [payload.id]: {
          id: payload.id,
          status: 'progress',
        },
      });
    });

    builder.addCase(collectionItemSubmitRejected, (state, { payload }) => {
      state.itemSubmits = _.merge(state.itemSubmits, {
        [payload.id]: {
          id: payload.id,
          status: 'error',
          error: payload.error,
        },
      });
    });

    builder.addCase(collectionItemSubmitSucceed, (state, { payload }) => {
      const { id, itemsType, items } = payload;
      const storedCollectionItemIDs = state.collections[id]?.items || [];
      const collectionItemIDs: string[] = Object.values(
        items[itemsType] || []
      ).map((item) => item.id);

      state.itemSubmits = _.merge(state.itemSubmits, {
        [payload.id]: {
          id: payload.id,
          status: 'success',
          itemsType,
          items: _.uniq([...collectionItemIDs, ...storedCollectionItemIDs]),
          error: undefined,
        },
      });
      state.items = _.merge(state.items, items);
    });

    builder.addCase(authDataSubmitSucceed, (state, { payload }) => {
      state.items = _.merge(state.items, payload.items);
    });
  },

  reducers: {
    cleanupContent: (state: TSliceContent) => {
      Object.keys(initialState).forEach((key) => {
        const initialValue = _.get(initialState, key);
        _.set(state, key, initialValue);
      });
    },
  },
});

export const { cleanupContent } = sliceContent.actions;

export default sliceContent.reducer;
