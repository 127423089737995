import { TAlert, TSitePage, TSiteSettings } from '../../Types/typesStructure';
import { useEffect, useMemo } from 'react';
import { RootState } from '../../Store/Store';
import { SITE_TEMPLATE } from '../../Settings/template';
import { TemplateElements } from './Components/TemplateElement/Components/TemplateElements';
import { execMultiplyActions } from '../../Library/Action/Helpers/execMultiplyActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { TSitePageContext } from './types';
import { getPageAlerts } from '../../Store/Slices/Structure/getPageAlerts';
import { TMutationContext } from '../../Library/Mutator/types';
import { Mutator } from '../../Library/Mutator/Mutator';
import { getSiteSettings } from '../../Store/Slices/Structure/getSiteSettings';
import {
  selectAlerts,
  selectSIteSets,
} from '../../Store/Slices/Structure/selectors';
import { selectLocale } from '../../Store/Slices/UI/selectors';
import { setNavigateBack } from '../../Store/Slices/UI/sliceUI';

/**
 * Шаблон страниц.
 - На странице происходит редирект в случае, если он есть в sliceUI -> redirectTo.
 - При загрузке страницы запускаются action`ы.
 - При помощи функции {@link constructAlertBlocks} собираются блоки с уведомлениями.
 - Блоки со значением атрибута fetchStatus отличным от success, догружаются отдельно (повторяется при изменении объекта page).
 * @returns Компонент страницы
 */
export const Page: React.FC<TSitePage> = (page) => {
  const setsNorm = useSelector(selectSIteSets);
  const allAlerts = useSelector(selectAlerts);
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();
  const siteSets = useMemo(
    () => getSiteSettings(setsNorm, allAlerts, locale),
    [allAlerts, locale, setsNorm]
  ) as TSiteSettings | undefined;

  const pageAlerts = useMemo(
    () => getPageAlerts(page.id, allAlerts, locale),
    [allAlerts, locale, page.id]
  );

  const pageContext: TSitePageContext = {
    id: page.id,
    title: page.title,
    description: page.description,
    url: page.url,
  };

  const mutator = new Mutator({ current: { page: pageContext } });
  const mutantAlerts = pageAlerts
    .map((alert) => mutator.mutate(alert) as TAlert)
    .filter(
      (item) => !item.showPositions?.find((position) => position.excluded)
    );

  // Запускаю экшны при загрузке страницы
  useEffect(() => {
    if (page.onLoadActions?.length) {
      execMultiplyActions(page.onLoadActions, mutator);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.onLoadActions]);

  const navigateTo = useSelector(
    (state: RootState) => state.sliceUI.navigateTo
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo.url);
      if (navigateTo.action) {
        execMultiplyActions(
          _.isArray(navigateTo.action)
            ? navigateTo.action
            : [navigateTo.action],
          mutator
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateTo]);

  const navigateBack = useSelector(
    (state: RootState) => state.sliceUI.navigateBack
  );

  useEffect(() => {
    if (navigateBack) {
      navigate(-1);
      dispatch(setNavigateBack());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateBack]);

  const contexts: TMutationContext['current'] = {
    page: {
      ...pageContext,
      alerts: mutantAlerts,
    },
  };

  return (
    <>
      <head>
        <TemplateElements
          elements={SITE_TEMPLATE.HEAD.CONSTANT_ITEMS}
          schema={{ path: ['staticHeadElements'] }}
          contexts={contexts}
        />
        <TemplateElements
          elements={siteSets?.headElements || SITE_TEMPLATE.HEAD.DEFAULT_ITEMS}
          contexts={contexts}
          schema={{
            path: [
              siteSets?.headElements ? 'headElements' : 'defaultHeadElements',
            ],
            attributes: siteSets?.headElements
              ? siteSets.mutationSchema?.attributes?.filter((item) =>
                  /^headElements\./.test(item)
                )
              : [],
          }}
        />
      </head>
      <body>
        <TemplateElements
          elements={SITE_TEMPLATE.BODY.CONSTANT_ITEMS}
          schema={{ path: ['staticBodyElements'] }}
          contexts={contexts}
        />
        <TemplateElements
          elements={page.template?.template || SITE_TEMPLATE.BODY.DEFAULT_ITEMS}
          contexts={contexts}
          schema={{
            ...page.template?.mutationSchema,
            path: [page.template ? 'template' : 'defaultBodyElements'],
          }}
        />
      </body>
    </>
  );
};
