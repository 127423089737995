import { takeEvery } from 'redux-saga/effects';
import { authDataSubmit } from '../Actions/actionsExtra';
import { workerAuthSubmit } from '../Workers/workerAuthSubmit';

/**
 * Сага отправки контента на сервер
 */
export function* sagaAuthDataSubmit() {
  yield takeEvery(authDataSubmit, workerAuthSubmit);
}
