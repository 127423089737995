import { TTemplateElem } from '../Components/Page/Components/TemplateElement/types';
import { TSitePage } from '../Types/typesStructure';
import { LOCALE_DEFAULT } from './api';

/**
 * Базовые элементы макета верстки
 */
type TSiteTemplate = {
  HEAD: {
    CONSTANT_ITEMS: TTemplateElem[];
    DEFAULT_ITEMS: TTemplateElem[];
  };
  BODY: {
    CONSTANT_ITEMS: TTemplateElem[];
    DEFAULT_ITEMS: TTemplateElem[];
  };
};

export const SITE_TEMPLATE: TSiteTemplate = {
  HEAD: {
    CONSTANT_ITEMS: [
      {
        tag: 'meta',
        charSet: 'UTF-8',
        contexts: {},
      },
      {
        tag: 'meta',
        name: 'viewport',
        content: 'width=device-width, initial-scale=1',
        contexts: {},
      },
    ],
    DEFAULT_ITEMS: [
      {
        tag: 'link',
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: '/assets/images/apple-touch-icon.png',
        contexts: {},
      },

      {
        tag: 'link',
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/assets/images/favicon-32x32.png',
        contexts: {},
      },

      {
        tag: 'link',
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/assets/images/favicon-16x16.png',
        contexts: {},
      },

      {
        tag: 'link',
        rel: 'mask-icon',
        href: '/assets/images/safari-pinned-tab.svg',
        color: '#5bbad5',
        contexts: {},
      },

      {
        tag: 'meta',
        name: 'msapplication-TileColor',
        content: '#da532c',
        contexts: {},
      },

      {
        tag: 'meta',
        name: 'theme-color',
        content: '#ffffff',
        contexts: {},
      },

      {
        tag: 'link',
        rel: 'manifest',
        href: '/site.webmanifest',
        contexts: {},
      },

      {
        tag: 'link',
        href: '/assets/css/bootstrap.min.css',
        rel: 'stylesheet',
        contexts: {},
      },
    ],
  },
  BODY: {
    CONSTANT_ITEMS: [
      {
        tag: 'noscript',
        contexts: {},
        children: [
          {
            tag: 'p',
            children:
              'Для отображения сайта необходимо включить поддержку JavaScript.',
            contexts: {},
          },
          {
            tag: 'p',
            children: 'JavaScript support must be enabled to display the site.',
            contexts: {},
          },
        ],
      },
    ],
    DEFAULT_ITEMS: [
      {
        tag: 'div',
        style: {
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#d8d8d8',
        },
        contexts: {},
        children: [
          {
            tag: 'style',
            children:
              '@keyframes rotate { 0% { transform: rotate(0deg); } 50% { transform: rotate(180deg); } 100% { transform: rotate(360deg); } } .base-spinner svg path { shape-rendering: auto; } .base-spinner { display: flex; animation: rotate 1s linear infinite; }',
            contexts: {},
          },
          {
            tag: 'div',
            className: 'base-spinner',
            style: {
              fontSize: '4rem',
              width: '4rem',
              height: '4rem',
            },
            contexts: {},
            children: [
              {
                tag: 'svg',
                viewBox: '0 0 25 25',
                height: '4rem',
                width: '4rem',
                xmlns: 'http://www.w3.org/2000/svg',
                contexts: {},
                children: [
                  {
                    tag: 'path',
                    fill: '#d8d8d8',
                    contexts: {},
                    d: 'M 19.149 2.501 L 19.149 12.506 C 19.151 13.985 18.661 15.424 17.758 16.593 C 16.856 17.763 15.591 18.597 14.163 18.966 C 12.735 19.334 11.226 19.216 9.873 18.628 C 8.52 18.041 7.4 17.017 6.691 15.721 L 6.691 20.616 C 8.178 21.689 9.932 22.33 11.758 22.468 C 13.585 22.606 15.414 22.235 17.044 21.397 C 18.675 20.558 20.043 19.285 20.999 17.716 C 21.955 16.148 22.46 14.345 22.461 12.506 L 22.461 5.8580000000000005 C 22.461 2.527 19.159 2.476 19.149 2.501 Z M 5.851 22.498 L 5.851 12.493 C 5.849 11.014 6.339 9.575 7.242 8.406 C 8.144 7.236 9.409 6.402 10.837 6.033 C 12.265 5.665 13.774000000000001 5.7829999999999995 15.127 6.371 C 16.48 6.958 17.6 7.982 18.309 9.278 L 18.309 4.383 C 16.822 3.31 15.068 2.669 13.242 2.531 C 11.415 2.393 9.586 2.7640000000000002 7.956 3.602 C 6.325 4.441 4.957 5.714 4.001 7.283 C 3.045 8.851 2.54 10.654 2.539 12.493 L 2.539 19.141 C 2.539 22.472 5.841 22.523 5.851 22.498 Z',
                  },
                ],
              },
            ],
          },
          {
            tag: 'p',
            className: 'mt-3',
            children: ' Загрузка...',
            contexts: {},
          },
        ],
      },
      {
        tag: 'div',
        style: {
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
        contexts: {},
        children: [
          {
            tag: 'p',
            children: 'Ошибка',
            className: 'fs-4',
            contexts: {},
          },
          {
            tag: 'p',
            children: '404',
            className: 'display-1 fw-medium',
            contexts: {},
          },
          {
            tag: 'p',
            children: 'Запрашиваемая страница - не найдена',
            contexts: {},
          },
        ],
      },
      {
        tag: 'div',
        style: {
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
        contexts: {},
        children: [
          {
            tag: 'p',
            children: 'Ошибка',
            className: 'fs-4',
            contexts: {},
          },
          {
            tag: 'p',
            children: '500',
            className: 'display-1 fw-medium',
            contexts: {},
          },
          {
            tag: 'p',
            children: 'Сервер не может обработать ваш запрос',
            contexts: {},
          },
        ],
      },
    ],
  },
};

export const DEFAULT_PAGES: Record<string, TSitePage> = {
  loading: {
    id: 'loading',
    locale: LOCALE_DEFAULT,
    url: '*',
    template: {
      id: 'loading',
      locale: LOCALE_DEFAULT,
      template: [SITE_TEMPLATE.BODY.DEFAULT_ITEMS[0]],
    },
  },
  error404: {
    id: 'error404',
    locale: LOCALE_DEFAULT,
    url: '/404',
    template: {
      id: '404',
      locale: LOCALE_DEFAULT,
      template: [SITE_TEMPLATE.BODY.DEFAULT_ITEMS[1]],
    },
  },
  error500: {
    id: 'error500',
    locale: LOCALE_DEFAULT,
    url: '/500',
    template: {
      id: '500',
      locale: LOCALE_DEFAULT,
      template: [SITE_TEMPLATE.BODY.DEFAULT_ITEMS[2]],
    },
  },
};
